/*| Primary Button */
.button_primary
{
    display: inline-flex;
    justify-content: center;
    align-items: center;

    background: var(--color-dark-purple);
    padding: 0.625em;
    width: 11em;
    height: 2.5em;

    font-family: var(--font-family-accent);
    font-weight: 400;
    font-size: 1.5rem;
    letter-spacing: 1px;
    color: var(--color-primary);

    transition: .3s ease;
}

    .button_primary:hover,
    button_primary:focus-visible
    {
        background: var(--color-accent-primary);
    }


/*| Ghost Button */
.button_ghost
{
    display: inline-flex;
    justify-content: center;
    align-items: center;

    border: 2px solid white;
    background: transparent;
    padding: 0.625em;
    width: 11em;
    height: 2.5em;

    font-family: var(--font-family-accent);
    font-weight: 400;
    font-size: 1.2rem;
    letter-spacing: 1px;
    color: var(--color-primary);

    transition: .3s ease;
}

    .button_ghost:hover,
    .button_ghost:focus-visible
    {
        background: var(--color-white);
        color: var(--color-black);
    }

/*| Highlight */
.highlight_purple
{
    position: relative;
}

.highlight_purple::before
{
    background-color: var(--color-accent-primary);
    content: "";
    width: 100%;
    height: 90%;
    position: absolute;
    opacity: 0.78;
    z-index: -1;
}

/*| Underscored*/
/*Expands a border from left to right*/
.underscored
{
    position: relative;

    font-weight: 500;
    text-decoration: none;
    color: var(--color-primary);
}

.underscored:after
{
    content: "";
    position: absolute;
    background-color: var(--color-white);
    height: 3px;
    width: 0%;
    left: 0;
    bottom: -5px;

    transition: 0.3s;
}

    .underscored:hover:after
    {
        width: 100%;
    }

    .underscored:focus-visible
    {
        outline: 0;
    }

    .underscored:focus-visible:after
    {
        width: 100%;
    }

/*| Underscored Visible*/
/*Same as Underscored, however the border is initially visible*/
.underscored_visible
{
    display: inline-block;
    position: relative;
    font-size: 1.25rem;
    text-decoration: none;
}

.underscored_visible:before,
.underscored_visible:after
{
    content: "";
    position: absolute;
    background-color: rgba(255, 255, 255, 0.6);
    height: 3px;
    width: 100%;
    left: 0;
    bottom: -5px;

    transition: 0.3s;
}

.underscored_visible:after
{
    transform: scaleX(0);
    background-color: rgba(255, 255, 255, 1);

    transition: transform 0.3s ease-in-out;
    transform-origin: left;
}

    .underscored_visible:focus-visible
    {
        outline: 0;
    }

    .underscored_visible:hover:after,
    .underscored_visible:focus-visible:after
    {
        transform: scaleX(1);
    }

@media screen and (max-width: 60em)
{
    .underscored_visible
    {
        font-size: 1.05rem;
    }
}

@media screen and (max-width: 48em)
{
    .underscored_visible
    {
        font-size: 1.25rem;
    }

    .underscored_visible:after
    {
        transform: unset;
    }

    .button_ghost
    {
        font-size: 1.175rem;
    }
}

@media screen and (max-width: 30em)
{
    .button_ghost
    {
        font-size: 0.975rem;
    }
}

@media screen and (max-height: 30em)
{
    .underscored_visible
    {
        font-size: 1.45rem;
    }

    .button_ghost
    {
        font-size: 1.175rem;
    }
}

@media screen and (min-width: 128em)
{

}


