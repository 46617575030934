#content
{
    background: var(--background-primary);
}

/*| Hero */
#hero
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: var(--page-landing-background-medium);

    width: 100%;
    height: 100vh;

    text-align: center;
    margin-bottom: 5em;
}

#hero_header
{
    font-size: 3.5rem;
    font-weight: 300;
    text-align: center;
}

#caption
{
    margin-bottom: 0;

    font-family: var(--font-family-accent);
    font-size: 0.95rem;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;

    opacity: 0.9;
}

/*| Links */
#hero_links
{
    display: flex;
    justify-content: center;
    gap: 5em;
}

/* ||  Projects */

#projects
{
    padding: 5em 0;
    /*border: 1px solid lightblue;*/
}

#projects_header
{
    display: flex;
    justify-content: space-between;
    align-items: center;

    /*border: 1px solid lightblue;*/
}

#projects_header > h2,
#projects_header > a
{
    margin: 0;
}

#projects_header > h2
{
    font-size: 2.5rem;
}

@media screen and (max-width: 60em)
{
    #hero
    {
        background: var(--page-landing-background-low);
    }

    #hero_header
    {
        font-size: 4.875rem;
        max-width: 100%;
    }

    #caption
    {
        font-size: 1.05rem;
    }
}

@media screen and (max-width: 48em)
{
    #hero_header
    {
        font-size: 2.575rem;
    }

    #hero_links
    {
        gap: 2em;
    }

    #projects_header > a
    {
        display: none;
    }
}


@media screen and (max-width: 30em)
{
    #hero_header
    {
        font-size: 2.375rem;
        max-width: unset;
    }

    #projects_header > h2
    {
        font-size: 2rem;
    }

    #projects_header > a
    {
        display: none;
    }
}

@media screen and (max-height: 30em)
{
    #hero_header
    {
        font-size: 2.175rem;
        max-width: unset;
    }

    #projects_header > h2
    {
        font-size: 2rem;
    }

    #projects_header > a
    {
        display: none;
    }
}

@media screen and (min-width: 128em)
{
    #hero
    {
        background: var(--page-landing-background-high);
    }
}