/* Containers */
#content
{
    margin: 0;
}

.container
{
    width: 100%;
    max-width: 72%;
    margin-left: auto;
    margin-right: auto;
}

.content-container
{
    padding: 0 3em;
}

/* Case Study Related */

/* The main container for case studies */
.case-study-container
{
    display: flex;
}

/* The container for the case study content */
.case-study-content-container
{
    padding: 0 3em;
}

.case-study-content
{
    /*border: 1px solid lightpink;*/
}

.case-study-content > section
{
    padding-top: 6em;
    padding-bottom: 6em;
}

/* Image layouts for case studies */
.image-wrapper
{
    margin-top: 2em;
    margin-bottom: 2em;
    max-width: 100%; /* Set the maximum width to prevent overflow */
    width: 100%; /* Set the width to 100% to fill the available space */
    /*overflow: hidden;*/

    /*border: 2px solid lightblue;*/
}

.image-wrapper img
{
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    filter: brightness(0.85);
}

.image-wrapper button
{
    border: none;
    background: transparent;
}

.image-wrapper button:focus-visible
{
    /*outline: 2px solid var(--color-white);*/
}

.image-wrapper img:hover
{
    cursor: pointer;
}

.image-wrapper img:focus-visible
{
    outline: 2px solid var(--color-white);
}

.image-scroll-preview
{
    /*border: 1px solid lightblue;*/
    max-height: 600px;
    overflow-y: scroll !important;
}

@media (max-width: 80em)
{
    .case-study-container
    {
        display: block;
    }

    .content-container
    {
        padding: 0 0.05em;
    }

    .case-study-content-container
    {
        padding: 0 0.05em;
    }


}

@media (max-width: 60em)
{
    .container
    {
        max-width: 85%;
    }
}

@media (min-width: 75em)
{
    .container
    {
        max-width: 72%;
    }
}

@media (min-width: 120em)
{
    .container
    {
        max-width: 58%;
    }
}
