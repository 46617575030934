#content
{
    background: var(--background-primary);
}

/*| Hero */
#hero
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding-top: 10em;

    width: 100%;
    height: 100%;
}

#hero_header
{
    font-size: 3.5rem;
    font-weight: 300;
    text-align: center;
}

#caption
{
    margin-bottom: 0;

    font-family: var(--font-family-accent);
    font-size: 0.95rem;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;

    opacity: 0.9;
}

/* ||  Projects */
#projects
{
    padding: 5em 0;
    /*border: 1px solid lightblue;*/
}

@media screen and (max-width: 60em)
{
    #caption
    {
        font-size: 1.05rem;
    }
}

@media screen and (max-width: 48em)
{
    #hero_header
    {
        font-size: 2.575rem;
    }
}

@media screen and (max-height: 30em)
{
    #hero_header
    {
        font-size: 2.175rem;
    }
}