#footer
{
    text-align: center;

    /*border-top: 2px solid rgba(211, 120, 212, 0.45);*/
    /*border-left: 1px solid lightblue;*/
    /*border-right: 1px solid lightblue;*/
}


/* ||  Footer Bottom */
#footer_bottom
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2em 0;


    background: var(--background-primary);
    border-top: 2px solid var(--color-dark-white);
}

.copyright
{
    margin: 0;
    font-size: 1.075rem;
}

@media (max-width: 60em)
{
    .copyright
    {
        font-size: 1.075rem;
    }

}

@media screen and (max-width: 48em)
{
    .copyright
    {
        font-size: 0.95rem;
    }
}

@media screen and (max-width: 30em)
{

}

@media screen and (max-height: 30em)
{

}

@media screen and (min-width: 128em)
{

    .copyright
    {
        font-size: 1.35rem;
    }
}

