#main
{
    position: relative;
    background: #121212;
    /*border-left: 1px solid lightblue;*/
    /*border-right: 1px solid lightblue;*/
}

#content
{
    /*border-left: 1px solid lightblue;*/
    /*border-right: 1px solid lightblue;*/
}

.mobile_view_all_links
{
    display: none;
}

/* ||  Hero */

#hero
{
    display: flex;
    flex-direction: column;
    justify-content: center;

    background: var(--page-landing-background-medium);
    width: 100%;
    height: 100vh;
}

#hero_container
{
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
}

#hero_caption
{
    font-size: 1.525rem;
    opacity: 0.9;
}

#hero_subcaption
{
    font-size: 3.5rem;
    font-weight: 300;
    max-width: 32ch;
}

#figma_cursor
{
    position: absolute;
    right: 5%;
    top: 80%;
    animation: 20s hovering infinite;
}

#figma_cursor svg
{
    margin-bottom: 0.525em;
}

#figma_cursor_name
{
    display: inline-block;
    margin-left: -0.525em;
    padding: 0.2em 0.3em;

    color: var(--color-white);
    font-size: 0.875rem;
    font-family: var(--font-family-accent);
    background-color: var(--color-accent-primary);
}

@keyframes hovering
{
    0% {
        transform: translate3d(0px, 0px, 0px);
    }

    10% {
        transform: translate3d(10px, 10px, 0px);
    }

    20% {
        transform: translate3d(0px, 0px, 0px);
    }

    25% {
        transform: translate3d(-40px, 20px, 0px);
    }

    30% {
        transform: translate3d(-45px, 25px, 0px);
    }

    35% {
        transform: translate3d(-40px, 20px, 0px);
    }

    50% {
        transform: translate3d(-40px, 20px, 0px);
    }

    55% {
        transform: translate3d(-200px, 40px, 0px);
    }

    60% {
        transform: translate3d(-205px, 45px, 0px);
    }

    65% {
        transform: translate3d(-200px, 40px, 0px);
    }

    70% {
        transform: translate3d(-205px, 45px, 0px);
    }

    95% {
        transform: translate3d(-200px, 40px, 0px);
    }
}

/* ||  Projects */

#projects,
#services
{
    padding: 5em 0;
    /*border: 1px solid lightblue;*/
}

#projects_header
{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    /*border: 1px solid lightblue;*/
}

#projects_header > h2,
#projects_header > a
{
    margin: 0;
}


@media screen and (max-width: 80em)
{

    #hero_caption
    {
        font-size: 1.675rem;
    }

    #hero_subcaption
    {
        font-size: 3rem;
    }
}

@media screen and (max-width: 60em)
{

    #hero
    {
        background: var(--page-landing-background-low);
    }

    #hero_caption
    {
        font-size: 2.175rem;
    }

    #hero_subcaption
    {
        font-size: 3.4rem;
    }
}

@media screen and (max-width: 60em) and (orientation: landscape)
{
    #hero_caption
    {
        font-size: 1.275rem;
    }

    #hero_subcaption
    {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 48em)
{

    #projects_header > a
    {
        display: none;
    }

    .mobile_view_all_links
    {
        display: inline-block;
    }
}

@media screen and (max-width: 40em)
{
    #hero_caption
    {
        font-size: 2.6rem;
        max-width: unset;
    }
}

@media screen and (max-width: 30em)
{
    #hero_caption
    {
        font-size: 1.2rem;
    }

    #hero_subcaption
    {
        font-size: 1.875rem;
    }

    #figma_cursor
    {
        top: 90%;
    }
}

@media screen and (max-width: 23em)
{
    #hero_caption
    {
        font-size: 1.575rem;
    }
}

@media screen and (min-width: 128em)
{
    #hero
    {
        background: var(--page-landing-background-high);
    }
}

@media screen and (min-width: 160em)
{

}