/* Skip Link */
#skipLink
{
    top: 1em;
    left: 4em;
    position: absolute;
    transform: translateY(-200%);
    transition: transform 0.3s;

    color: white;

    z-index: 3;
}

    #skipLink:focus
    {
        transform: translateY(0%);
    }

/* Scroll Title */
#scroll_title
{
    display: none;
    font-size: 1.05rem;
    font-weight: 500;
    text-decoration: none;
    font-family: var(--font-family-accent);
}

/* Scroll Indicator */
#scroll_indicator
{
    z-index: 1;

    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    height: 2px;
    width: 0%;

    background-color: var(--color-white);
}

/* Header */
#header
{
    display: flex;
    flex-direction: column;

    position: fixed;
    z-index: 2;
    top: 0;
    padding: 1.25em 0;
    width: 100vw;

    border-bottom: 0;

    background-color: transparent;
    transition: all 0.2s ease-in-out;

    /*border: 1px solid lightblue;*/
}

#header_container
{
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0.25em 0;
    /*border: 1px solid lightblue;*/

}

#header_container > a
{
    font-size: 1.05rem;
    font-weight: 500;
    /*text-transform: uppercase;*/
    text-decoration: none;
}

#nav_item_contact_link
{
    border: 1px solid white;
    padding: 0.35em 0.85em;
    transition: background-color 0.3s ease;
}

#nav_item_contact_link:focus-visible,
#nav_item_contact_link:hover
{
    background: var(--color-white);
    color: var(--color-black);
    outline: 0;
}

/* Main Navigation */
#main_nav
{
    display: block;
}

#nav_items
{
    display: flex;
    align-items: center;
    gap: 1.25em;
    list-style: none;

    margin: 0;
    line-height: 0;
}

    #nav_items > li
    {
        margin: 0;
        font-weight: 400;
        font-size: 1.05rem;
        /*text-transform: uppercase;*/
    }

/* Mobile Navigation */
#menu_button
{
    display: none;
    border: none;
    background: transparent;

    max-width: 100%;
    height: auto;
    margin-left: auto;

    z-index: 2;
}

#menu_button img
{
    max-width: 100%;
    transition: transform 0.3s ease-in-out;
}

.rotate
{
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
}

#mobile_nav
{
    z-index: 1;

    display: none;
    position: fixed;
    top: 6em;
    left: 0;

    width: 100%;
    height: 100vh;
    padding-top: 2em;
    border-top: 2px solid var(--color-dark-white);

    background: var(--background-primary);
}

#mobile_nav #nav_items
{
    flex-direction: column;
    align-items: flex-start;

}

#mobile_nav #nav_items > li
{
    padding: 1em 0;
    font-size: 1.05rem;
}


@media screen and (max-width: 60em)
{
    .skipLink
    {
        display: none;
    }

    #main_nav
    {
        display: none;
    }

    #header_container > a,
    #mobile_nav #nav_items > li
    {
        font-size: 1.85rem;
    }

    #menu_button
    {
        display: block;
        width: 50px;
    }

    #close_menu_button
    {
        right: 1em;
    }

    #close_menu_button img
    {
        width: 60px;
        height: 60px;
    }
}

@media screen and (max-width: 60em) and (orientation: landscape)
{
    #header_container > a,
    #scroll_title,
    #mobile_nav #nav_items > li
    {
        font-size: 1.25rem;
    }

    #mobile_nav #nav_items > li
    {
        padding: 0.85em 0;
    }

    #menu_button
    {
        display: block;
        width: 35px;
    }
}

@media screen and (max-width: 48em)
{
    #skipLink
    {
        display: none;
    }

    #scroll_title
    {
        overflow: hidden;
        max-width: 85%;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    #header_container > a,
    #mobile_nav #nav_items > li,
    #scroll_title
    {
        font-size: 1.75rem;
    }
}


@media screen and (max-width: 48em) and (orientation: landscape)
{
    #header_container > a,
    #scroll_title
    {
        font-size: 1.05rem;
    }

    #mobile_nav #nav_items > li
    {
        font-size: 1.15rem;
        padding: 0.5em 0;
    }

    #mobile_nav
    {
        top: 4em;
    }

    #menu_button
    {
        width: 25px;
    }
}

@media screen and (max-width: 30em)
{
    #header_container > a,
    #scroll_title
    {
        font-size: 1.25rem;
    }

    #mobile_nav
    {
        top: 5em;
    }

    #mobile_nav #nav_items > li
    {
        font-size: 1.35rem;
        padding: 1em 0;
    }

    #menu_button
    {
        width: 35px;
    }
}

@media screen and (max-width: 23em)
{
    #header_container > a,
    #scroll_title
    {
        font-size: 1.15rem;
    }

    #mobile_nav #nav_items > li
    {
        font-size: 1.25rem;
        padding: 1em 0;
    }

    #menu_button
    {
        width: 30px;
    }
}


/*@media screen and (max-height: 30em)*/
/*{*/
/*    #header_container > a*/
/*    {*/
/*        font-size: 1.45rem;*/
/*    }*/

/*    #mobile_nav #nav_items > li*/
/*    {*/
/*        font-size: 1.05rem;*/
/*    }*/

/*    #menu_button*/
/*    {*/
/*        width: 40px;*/
/*    }*/
/*}*/

@media screen and (min-width: 128em)
{

}

@media screen and (min-width: 160em)
{

}