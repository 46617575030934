:root
{
    --font-family-primary: 'Sohne', 'Roboto', sans-serif;
    --font-family-secondary: 'Source Serif Pro', serif;
    --font-family-accent: 'Sohne', 'Roboto', sans-serif;

    --background-primary: var(--color-dark-gray);
    --navigation-primary: var(--color-dark-gray);
    --color-primary: var(--color-white);
    --color-accent-primary: var(--color-dark-purple);

    --color-black: #11151C;
    --color-white: white;
    --color-dark-white: rgba(255, 255, 255, 0.65);
    --color-purple: #D378D4;
    --color-dark-purple: #894E8A;
    --color-dark-purple-2: #2A182A;
    --color-dark-purple-3: rgba(211, 120, 212, 0.45);
    --color-dark-gray: #121212;
    --color-light-gray: #1B1B1B;
    --color-dark-blue: #292A37;
    --color-dark-red: #EC6469;
    --color-dark-green: #198562;

    --linear-gradient-purple:  linear-gradient(90deg, rgba(220, 148, 221, 0.65) 0%, rgba(214, 91, 216, 0.65) 65.71%, rgba(208, 29, 211, 0.65) 101.75%, rgba(208, 29, 211, 0.65) 101.75%);
    --linear-gradient-white: linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75));
    --linear-gradient-gray: linear-gradient(0deg, rgba(18, 18, 18, 0.75), rgba(18, 18, 18, 0.75));

    --page-landing-background-high: linear-gradient(0deg, rgba(18, 18, 18, 0.85), rgba(18, 18, 18, 0.85)),
    center / cover no-repeat fixed url("../../resources/images/Retro-Background-1080x720-BW.png");
    --page-landing-background-medium: linear-gradient(0deg, rgba(18, 18, 18, 0.85), rgba(18, 18, 18, 0.85)),
    center / cover no-repeat fixed url("../../resources/images/Retro-Background-1920x1280-BW.png");
    --page-landing-background-low: linear-gradient(0deg, rgba(18, 18, 18, 0.85), rgba(18, 18, 18, 0.85)),
    center / cover no-repeat scroll url("../../resources/images/Retro-Background-2560x1707-BW.png");
}