.service_item
{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    padding: 1.5em 1.25em;
    height: 12em;
    border: 2px solid rgba(255, 255, 255, 0.65);
    border-radius: 10px;
    transition: border-top-color .3s ease-in-out,
                border-right-color .3s ease-in-out;


    text-decoration: none;
}

    .service_item:hover,
    .service_item:focus-visible
    {
        outline: 0;
        border: 2px solid var(--color-white);
        animation: border-color-animation 2s linear;
    }

.service_item_content
{
    max-width: 31.25em;
    margin-right: 1.25em;
}

.service_item_content p
{
    font-family: var(--font-family-accent);
}

.service_item::after
{
    margin-left: auto;
    align-self: center;
    content: ">";
    font-weight: 400;
    font-size: 1.5rem;
}

.service_title
{
    margin-bottom: 0.25rem;
    font-weight: 400;
    font-size: 1.5rem;
    font-family: var(--font-family-accent);
}

@media screen and (max-width: 40em)
{
    .service_item
    {
        height: 2.25em;
        align-items: center;
    }

    .service_item_content p
    {
        display: none;
    }
    .service_title
    {
        font-size: 1.35rem;
    }
}

@media (prefers-reduced-motion: reduce)
{
    .service_item
    {
        transition: none;
    }
}