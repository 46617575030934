#content
{
    background: var(--background-primary);
}

.image_wrapper
{
    padding: 2em 0;
}

.image_wrapper img
{
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    filter: brightness(0.85);
}

/*| Hero */
#hero
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: var(--page-landing-background-medium);

    width: 100%;
    height: 100vh;

    /*padding: 0 var(--page-margin);*/
}

#hero_header
{
    font-size: 3.5rem;
    font-weight: 300;
    text-align: center;
}

#caption
{
    margin-bottom: 0;

    font-family: var(--font-family-accent);
    font-size: 0.95rem;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;

    opacity: 0.9;
}

/*| About*/
#content_container > section
{
    padding-top: 5em;
    padding-bottom: 5em;
}

#schedule_call_link
{
    font-size: inherit;
}

/*| Contact */
#contact_instructions h2
{
    margin-top: 2em;
}


/*| Contact Form */
#contact_form
{
    display: flex;
    flex-direction: column;
    gap: 2em;

    margin-top: 3em;
}

.contact_form_input_container
{
    display: flex;
    flex-direction: column;
    gap: 0.85em;
    width: 100%;
}

.contact_form_input_container label
{
    font-family: var(--font-family-primary);
    font-weight: 300;
    font-size: 1.05rem;
}

.contact_form_input_container input
{
    min-height: 2em;
}

.contact_form_input_container textarea
{
    min-height: 10em;
    resize: vertical;
}

.contact_form_input_container p
{
    visibility: hidden;

    margin-top: 0;
    color: #EC6469;
    font-size: 0.985rem;
    font-family: var(--font-family-accent);
}

.contact_form_input_container input,
.contact_form_input_container textarea
{
    background: var(--color-light-gray);
    border: 1px solid var(--color-dark-white);
    padding: 0.65em;
    max-width: 100%;

    color: var(--color-white);
    font-family: var(--font-family-primary);
}

.contact_form_input_container input:focus,
.contact_form_input_container textarea:focus
{
    border: 1px solid var(--color-white);
    outline: 0;
}

#contact_form_submit
{
    width: 25em;
    max-width: 100%;
}

#toast
{
    z-index: 1;
    visibility: hidden;

    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    position: fixed;
    bottom: 4em;
    right: 50%;
    padding: 0.65em;
    width: 20em;
    max-width: 65vw;
    border-left: 5px solid var(--color-dark-green);
    box-shadow: 0px 2px 1px 0px rgba(255, 255, 255, 0.25);
    transform: translateX(50%);
    background: var(--color-light-gray);


    text-align: center;
    color: white;

    animation: fadein 0.5s;
}

#toast_alert
{
    margin-top: 0;
    padding-left: 0.25em;

    font-family: var(--font-family-accent);
}

#toast_close
{
    border: none;
    background: transparent;
}

#toast_close:hover,
#toast_close:focus-visible
{
    opacity: 0.75;
}

#toast_close img
{
    width: 25px;
    height: 25px;
}

@keyframes fadein
{
    from {bottom: 0; opacity: 0;}
    to {bottom: 4em; opacity: 1;}
}

@keyframes fadeout
{
    from {bottom: 4em; opacity: 1;}
    to {bottom: 0; opacity: 0;}
}


@media screen and (max-width: 60em)
{
    #hero
    {
        background: var(--page-landing-background-low);
    }

    #hero_header
    {
        font-size: 2.65rem;
    }

    #caption
    {
        font-size: 1.05rem;
    }
}

@media screen and (max-width: 48em)
{
    #hero_header
    {
        font-size: 2.375rem;
    }
}

@media screen and (max-width: 30em)
{

}


@media screen and (min-width: 128em)
{
    #hero
    {
        background: var(--page-landing-background-high);
    }
}