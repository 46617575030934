/* | Roboto */
@font-face
{
    font-family: Roboto;
    src: url("../../resources/fonts/Roboto/Roboto-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face
{
    font-family: Roboto;
    src: url("../../resources/fonts/Roboto/Roboto-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face
{
    font-family: Roboto;
    src: url("../../resources/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face
{
    font-family: Roboto;
    src: url("../../resources/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
    font-weight: 500;
}

/* Source Serif Pro*/
@font-face
{
    font-family: Source Serif Pro;
    src: url("../../resources/fonts/Source Serif Pro/SourceSerifPro-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face
{
    font-family: Source Serif Pro;
    src: url("../../resources/fonts/Source Serif Pro/SourceSerifPro-LightIt.woff") format("woff");
    font-weight: 300;
    font-style: italic;
}


@font-face
{
    font-family: Source Serif Pro;
    src: url("../../resources/fonts/Source Serif Pro/SourceSerifPro-It.otf") format("otf");
    font-weight: normal;
    font-style: italic;
}


@font-face
{
    font-family: Source Serif Pro;
    src: url("../../resources/fonts/Source Serif Pro/SourceSerifPro-Semibold.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face
{
    font-family: Source Serif Pro;
    src: url("../../resources/fonts/Source Serif Pro/SourceSerifPro-SemiboldIt.woff") format("woff");
    font-weight: 500;
    font-style: italic;
}

/* Sohne */
@font-face
{
    font-family: Sohne;
    src: url("../../resources/fonts/Sohne/soehne-leicht.woff2") format("woff");
    font-weight: 300;
}

@font-face
{
    font-family: Sohne;
    src: url("../../resources/fonts/Sohne/soehne-buch.woff2") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face
{
    font-family: Sohne;
    src: url("../../resources/fonts/Sohne/soehne-kraftig.woff2") format("woff");
    font-weight: 500;
}

@font-face
{
    font-family: Sohne;
    src: url("../../resources/fonts/Sohne/soehne-halbfett.woff2") format("woff");
    font-weight: 600;
}