.project
{
    text-decoration: none;
    /*border: 1px solid lightpink;*/
}

    .project:hover
    {
        cursor: pointer;
    }

    .project:focus
    {
        outline: 0;
    }

    .project:hover .project_thumbnail > img,
    .project:focus .project_thumbnail > img
    {
        transform: scale(1.03);
    }

.project_thumbnail
{
    display: block;
    overflow: hidden;
    /*border: 1px solid lightblue;*/
    margin: 0 auto;

}

.project_thumbnail > img
{
    max-width: 100%;
    height: auto;
    /*border: 1px solid lightblue;*/

    object-fit: cover;
    transform: scale(1);
    transition: 0.5s ease-in-out;
}

.project_content
{
    margin-top: 1.5em;
    font-family: var(--font-family-accent);
}

.project_content_title
{
    position: relative;
    font-weight: 400;
    transition: 0.3s ease-in-out;

}

    .project_content_title:after
    {
        content: "";
        position: absolute;
        background-color: var(--color-white);
        height: 3px;
        width: 0%;
        left: 0;
        bottom: -5px;

        transition: 0.3s;
    }

    .project:hover .project_content_title:after,
    .project:focus-visible .project_content_title:after
    {
        width: 100%;
    }

@media (max-width: 80em)
{

}

@media (max-width: 40em)
{
    .project_thumbnail > img
    {
        max-height: 300px;
    }
}