*
{
    margin: 0;
    padding: 0;
}

html
{
    font-size: 16px;
}

body
{
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;

    background: var(--background-primary);
    color: var(--color-primary);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    letter-spacing: -0.003rem;
    font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6
{
    font-family: var(--font-family-primary);
}

p,
ul,
li,
caption
{
    font-family: var(--font-family-secondary);
}

a,
button
{
    font-family: var(--font-family-accent);
}

h1,
h2
h3,
h4,
h5,
h6,
p,
ul,
li,
button,
a
{
    color: var(--color-primary);
}

h1
{
    font-size: 2.85rem;
}

h2
{
    font-size: 2.2375rem;
    font-weight: 500;
}

h3
{
    font-size: 1.62rem;
    font-weight: 500;
}

h1,
h2,
h3
{
    opacity: 0.95;
}

p,
ul
{
    margin-top: 0.625em;
}

p + p
{
    margin-top: 2em;
}

p,
ul,
caption
{
    font-size: 1.25rem;
    font-weight: 300;

    line-height: 1.5em;
    word-spacing: 0.03em;
    opacity: 0.95;
    letter-spacing: 0.01em;

    opacity: 0.95;
}

ul
{
    margin-left: 1.875em;
}

ul li
{
    margin-bottom: 1.5em;
}

caption
{
    display: block;

    opacity: 0.65;
    font-style: italic;
    font-weight: 500;
    font-size: 1.05rem;

    text-align: left;
    margin-top: 1.25em;
}

img
{
    display: block;
    -webkit-user-drag: none; /* Safari/Chrome */
    -khtml-user-drag: none; /* Konqueror */
    -moz-user-drag: none; /* Firefox */
    -o-user-drag: none; /* Opera */
    user-drag: none; /* Generic */
}

button:hover
{
    cursor: pointer;
}

@media screen and (max-width: 60em)
{

}

@media screen and (max-width: 48em)
{

}

@media screen and (max-width: 30em)
{
    h1
    {
        font-size: 1.95rem;
    }

    h2
    {
        font-size: 1.55rem;
    }

    h3
    {
        font-size: 1.25rem;
    }

    p,
    ul,
    caption
    {
        line-height: 1.45em;
        word-spacing: 0.04em;
        letter-spacing: 0.02em;
        font-size: 1.125rem;

    }

    p + p
    {
        margin-top: 1.5em;
    }

    caption
    {
        font-size: 0.85rem;
    }
}

/* @media screen and (min-width: 128em)
{
    html
    {
        font-size: 20px;
    }
}

@media screen and (min-width: 160em)
{
    html
    {
        font-size: 24px;
    }
} */