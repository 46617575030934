.service_collection
{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 4em 0;
    gap: 2em;

    /*border: 1px solid lightblue;*/
}

@media (max-width: 60em)
{
    .service_collection
    {
        grid-template-columns: repeat(1, 1fr);
    }
}
