.project_collection
{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: unset;
    gap: 3em;

    padding: 4em 0;
    /*border: 1px solid lightblue;*/
}

@media (max-width: 58em)
{
    .project_collection
    {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (min-width: 128em)
{

}
